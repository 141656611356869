import { type Component } from 'solid-js'

export const Logo: Component<{ class?: string }> = (props) => {
  return (
    <svg
      class={props.class}
      viewBox="0 0 260.543 55.721"
    >
      <path
        d="M239.928 28.129a6.69 6.69 0 1 1-6.69-6.69 6.69 6.69 0 0 1 6.69 6.69"
        data-name="Path 301"
        fill="currentColor"
      ></path>
      <path
        d="M238.805 45.216v4.795a5.711 5.711 0 0 1-11.422 0v-4.795a5.711 5.711 0 1 1 11.422 0"
        data-name="Path 302"
        fill="#ff9580"
      ></path>
      <path
        d="M238.805 5.711v4.794a5.711 5.711 0 0 1-11.422 0V5.711a5.711 5.711 0 1 1 11.422 0"
        data-name="Path 303"
        fill="#7cf2db"
      ></path>
      <path
        d="m255.008 18.464-3.39 3.39a5.711 5.711 0 0 1-8.076-8.076l3.39-3.39a5.711 5.711 0 0 1 8.076 8.077"
        data-name="Path 304"
        fill="#9580ff"
      ></path>
      <path
        d="m250.8 54.056-3.39-3.39a5.711 5.711 0 0 1 8.076-8.076l3.39 3.39a5.711 5.711 0 0 1-8.077 8.076"
        data-name="Path 305"
        fill="#88f27c"
      ></path>
      <path
        d="M28.509 46.441c-3.809-5.547-7.655-11.072-11.547-16.686l2.051-2.05 8.363-8.312a9.489 9.489 0 0 1 1.819-1.48q1.09-.7 1.688-1.143a1.2 1.2 0 0 0 .6-.909v-.156q0-.727-1.091-.727h-6.444q-1.144 0-1.143.727v.156a1.9 1.9 0 0 0 .519 1.013 3.648 3.648 0 0 1 .649 1.065q.129.416-.649 1.454L8.208 34.562V19.289a2.684 2.684 0 0 1 1.039-2.052q1.039-.907 1.039-1.376v-.156q0-.727-1.091-.727h-8.1Q0 14.978 0 15.705v.156q0 .468.961 1.376a2.768 2.768 0 0 1 .961 2.052v28.208a2.849 2.849 0 0 1-.961 2.078Q0 50.509 0 50.977v.156q0 .728 1.091.728h8.1q1.142 0 1.143-.728v-.156q0-.468-1.065-1.4a2.734 2.734 0 0 1-1.065-2.078V38.51l4.245-4.245q4.994 7.534 9.992 15.066a6.325 6.325 0 0 0 6.511 2.947 5.374 5.374 0 0 0 4.4-3.461 5.282 5.282 0 0 1-4.844-2.376"
        data-name="Path 306"
        fill="currentColor"
      ></path>
      <path
        d="M46.18 26.406a12.622 12.622 0 0 0-9.143 3.636 12.162 12.162 0 0 0-3.74 9.091 12.838 12.838 0 0 0 3.844 9.351 13.729 13.729 0 0 0 10.234 3.9 11.863 11.863 0 0 0 9.039-3.69 12.811 12.811 0 0 0 3.48-9.143 12.644 12.644 0 0 0-3.844-9.325 13.409 13.409 0 0 0-9.869-3.818m6.026 21.686a4.706 4.706 0 0 1-4.052 2.312q-3.793 0-6.259-4.1a17.492 17.492 0 0 1-2.468-9.195 10.91 10.91 0 0 1 1.637-6.468 5.2 5.2 0 0 1 4.436-2.264q3.532 0 5.9 3.9a17.8 17.8 0 0 1 2.363 9.4 11.377 11.377 0 0 1-1.557 6.415"
        data-name="Path 307"
        fill="currentColor"
      ></path>
      <path
        d="M88.937 49.626a2.813 2.813 0 0 1-.987-2.078V13.835q0-1.558-1.818-.935l-5.507 1.766q-.935.364-.935 1.039v.1a1.071 1.071 0 0 0 .6.858 6.916 6.916 0 0 1 1.194 1.013 2.232 2.232 0 0 1 .6 1.61v9.766a9.435 9.435 0 0 0-6.8-2.649 11.6 11.6 0 0 0-8.7 3.636 12.846 12.846 0 0 0-3.506 9.3 12.833 12.833 0 0 0 3.558 9.454 11.611 11.611 0 0 0 8.531 3.586 8.7 8.7 0 0 0 7.273-3.325l.155 1.818a.958.958 0 0 0 1.039.988h5.4q.883 0 .883-.728v-.153q0-.415-.987-1.351m-6.857-5.922a6.854 6.854 0 0 1-1.689 4.935 5.162 5.162 0 0 1-3.818 1.766q-3.481-.051-5.585-3.9a18.369 18.369 0 0 1-2-9.3A10.82 10.82 0 0 1 70.8 30.74a5.554 5.554 0 0 1 4.675-2.364 6.03 6.03 0 0 1 4.623 2.208 7.074 7.074 0 0 1 1.974 4.805Z"
        data-name="Path 308"
        fill="currentColor"
      ></path>
      <path
        d="M115.192 43.808a.952.952 0 0 0-1.065.415 6.686 6.686 0 0 1-2.572 2.728 7.655 7.655 0 0 1-4.078 1.064 7.48 7.48 0 0 1-4.675-1.507 9.018 9.018 0 0 1-2.96-4.207l12.571-4.623a3.578 3.578 0 0 0 2.649-3.636 7.05 7.05 0 0 0-2.52-5.325q-2.52-2.311-7.195-2.311a11.938 11.938 0 0 0-8.805 3.637 12.443 12.443 0 0 0-3.61 9.143 13.19 13.19 0 0 0 3.507 9.558 11.719 11.719 0 0 0 8.8 3.636 10.754 10.754 0 0 0 7.325-2.441 8.731 8.731 0 0 0 3.221-5.195.778.778 0 0 0-.6-.935m-16.234-6.961a11.476 11.476 0 0 1 1.454-6.13 4.417 4.417 0 0 1 3.9-2.337 4.208 4.208 0 0 1 3.507 2 6.7 6.7 0 0 1 1.428 3.87 2.212 2.212 0 0 1-1.351 2.182l-8.623 3.74a16.92 16.92 0 0 1-.312-3.325"
        data-name="Path 309"
        fill="currentColor"
      ></path>
      <path
        d="m144.204 47.445-.624-10.078q-.311-5.558-3.064-8.259a9.279 9.279 0 0 0-6.754-2.7 8.384 8.384 0 0 0-7.272 3.48l.052-16.156q0-1.558-1.662-.831l-5.559 2.441q-.883.416-.882 1.091v.156a1.052 1.052 0 0 0 .545.805 6.5 6.5 0 0 1 1.091 1.013 2.279 2.279 0 0 1 .546 1.559v27.583a2.083 2.083 0 0 1-.52 1.454 11.33 11.33 0 0 1-1.039 1.013 1.223 1.223 0 0 0-.52.909v.156q0 .78 1.091.779h8.052q1.141 0 1.142-.779v-.156q0-.468-1.065-1.376a2.618 2.618 0 0 1-1.065-2V35.341a6.978 6.978 0 0 1 1.507-4.649 4.906 4.906 0 0 1 3.947-1.792q5.091 0 5.455 8.312l.52 10.285a2.594 2.594 0 0 1-.91 2.052q-.961.909-.961 1.376v.207q0 .728 1.091.728h7.637q1.09 0 1.09-.728v-.208a2.516 2.516 0 0 0-.883-1.429 3.32 3.32 0 0 1-.987-2.052"
        data-name="Path 310"
        fill="currentColor"
      ></path>
      <path
        d="M161.555 26.406a12.621 12.621 0 0 0-9.143 3.636 12.16 12.16 0 0 0-3.74 9.091 12.836 12.836 0 0 0 3.844 9.351 13.728 13.728 0 0 0 10.234 3.9 11.864 11.864 0 0 0 9.039-3.688 12.811 12.811 0 0 0 3.48-9.143 12.646 12.646 0 0 0-3.841-9.329 13.411 13.411 0 0 0-9.87-3.818m6.023 21.688a4.706 4.706 0 0 1-4.052 2.312q-3.792 0-6.26-4.1a17.5 17.5 0 0 1-2.467-9.195 10.913 10.913 0 0 1 1.636-6.468 5.2 5.2 0 0 1 4.442-2.259q3.532 0 5.9 3.9a17.8 17.8 0 0 1 2.363 9.4 11.372 11.372 0 0 1-1.559 6.415"
        data-name="Path 311"
        fill="currentColor"
      ></path>
      <path
        d="M193.034 26.406q-5.142 0-6.909 5.143v-4.052q0-1.61-1.507-.883l-5.091 2.338q-.935.52-.935 1.09v.156q0 .468 1.117 1.325a2.528 2.528 0 0 1 1.116 2.052v13.973a2.659 2.659 0 0 1-.987 2.026q-.987.884-.987 1.35v.208q0 .728 1.091.727h8.052q1.09 0 1.091-.727v-.208q0-.467-1.195-1.35a2.512 2.512 0 0 1-1.2-2.026V36.432a10.146 10.146 0 0 1 .935-4.572 2.9 2.9 0 0 1 2.65-1.87 2.008 2.008 0 0 1 1.558.624 2.887 2.887 0 0 1 .727 1.4 2.721 2.721 0 0 0 .78 1.4 2.388 2.388 0 0 0 1.766.623q2.7 0 2.7-3.272a4.186 4.186 0 0 0-1.272-3.117 4.8 4.8 0 0 0-3.507-1.246"
        data-name="Path 312"
        fill="currentColor"
      ></path>
      <path
        d="M216.436 44.743a.949.949 0 0 0-.753-.1.928.928 0 0 0-.623.52q-.572 2.7-3.689 2.7a2.633 2.633 0 0 1-2.13-1.013 4.345 4.345 0 0 1-.831-2.831V29.471h6.546q.935 0 .935-.883v-.731a.89.89 0 0 0-.935-.935h-6.546l-.052-5.039q0-1.141-.987-1.143h-.779q-.884 0-1.04 1.143a6.673 6.673 0 0 1-1.532 3.688 4.1 4.1 0 0 1-3.2 1.351h-1.61q-.884 0-.883.935v.727a.78.78 0 0 0 .883.883h2.961v14.496q0 4.1 1.948 6.26a6.815 6.815 0 0 0 5.324 2.156 7.05 7.05 0 0 0 5.325-2.078 6.816 6.816 0 0 0 2-4.883.733.733 0 0 0-.338-.675"
        data-name="Path 313"
        fill="currentColor"
      ></path>
    </svg>
  )
}
