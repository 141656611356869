import { Motion } from '@motionone/solid'
import { Show, type Component } from 'solid-js'

import { useMenuState } from '../widgets/Navigation'

export const ToggleMenu: Component = () => {
  const { menuState, toggleState } = useMenuState()
  return (
    <button
      aria-label="Toggle navigation menu"
      onClick={toggleState}
    >
      <Show when={menuState() === 'closed'}>
        <span class="sr-only">Open main menu</span>
      </Show>
      <Show when={menuState() === 'open'}>
        <span class="sr-only">Close menu</span>
      </Show>
      <svg
        aria-hidden="true"
        class="h-6 w-6"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        viewBox="0 0 24 24"
      >
        <Motion.path
          variant={{
            closed: { fill: 'red', rotate: 0 },
            open: { transform: { rotate: '45deg' } },
          }}
          animate={menuState()}
          d="M 4 6 L 20 6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Motion.path
          variant={{
            closed: { rotate: 0 },
            open: { rotate: 45 },
          }}
          animate={menuState()}
          d="M 4 12 L 20 12"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Motion.path
          variant={{
            closed: { rotate: 0 },
            open: { rotate: 45 },
          }}
          animate={menuState()}
          d="M 4 18 L 20 18"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  )
}
