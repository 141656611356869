import { type Component } from 'solid-js'

import { Navigation } from './Navigation.tsx'
import { ResizableHeader } from './ResizableHeader.tsx'

interface Link {
  ariaLabel?: string
  href?: string
  icon?: string
  text?: string
}

interface MenuLink extends Link {
  links?: Array<Link>
}

export const Header: Component<{
  links?: Array<MenuLink>
  showRssFeed?: boolean
  showToggleTheme?: boolean
}> = (props) => {
  return (
    <ResizableHeader>
      <Navigation
        links={props.links}
        showRssFeed={props.showRssFeed}
        showToggleTheme={props.showToggleTheme}
      />
    </ResizableHeader>
  )
}
