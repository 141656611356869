import { FaSolidMoon } from 'solid-icons/fa'
import { FiMonitor } from 'solid-icons/fi'
import { WiDaySunny } from 'solid-icons/wi'
import {
  createEffect,
  createSignal,
  onMount,
  Show,
  type Component,
} from 'solid-js'

const THEME = 'theme' as const

type Theme = 'dark' | 'light' | null

type Selection = 'auto' | 'dark' | 'light'

type Settings = {
  selection: Selection
  theme: Theme
}

const initTheme = () => {
  let theme: Theme
  if (typeof localStorage !== 'undefined' && localStorage.getItem(THEME)) {
    theme = localStorage.getItem(THEME) as Theme
  } else if (
    typeof window !== 'undefined' &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    theme = 'dark'
  } else {
    theme = 'light'
  }

  return theme
}

const initSelection = () => {
  return (
    typeof localStorage !== 'undefined' && localStorage.getItem(THEME)
      ? localStorage.getItem(THEME)
      : 'auto'
  ) as Selection
}

const initSettings = () => {
  const settings: Settings = {
    selection: initSelection(),
    theme: initTheme(),
  }

  return settings
}

export const ToggleTheme: Component = () => {
  const [settings, setSettings] = createSignal<Settings>(initSettings())

  onMount(() => {
    setSettings(initSettings())
  })

  createEffect(() => {
    const root = document.documentElement
    const { selection, theme } = settings()
    if (theme === 'light') {
      root.classList.remove('dark')
    } else {
      root.classList.add('dark')
    }
    switch (selection) {
      case 'auto':
        localStorage.removeItem(THEME)
        break

      case 'dark':
        localStorage.setItem(THEME, 'dark')
        break

      case 'light':
        localStorage.setItem(THEME, 'light')
        break
    }
  })

  return (
    <div>
      <Show when={settings().theme === 'dark'}>
        <button
          aria-label="Enable Light mode"
          classList={{ ['bg-red-100']: settings().theme === 'light' }}
          onClick={() => setSettings({ selection: 'light', theme: 'light' })}
        >
          <WiDaySunny />
        </button>
      </Show>
      <Show when={settings().theme === 'light'}>
        <button
          aria-label="Enable Dark mode"
          classList={{ ['bg-red-100']: settings().theme === 'dark' }}
          onClick={() => setSettings({ selection: 'dark', theme: 'dark' })}
        >
          <FaSolidMoon />
        </button>
      </Show>
      <Show when={false}>
        <button
          aria-label="Enable system default light or dark mode"
          classList={{ ['bg-red-100']: settings().theme === null }}
          onClick={() => setSettings({ selection: 'auto', theme: null })}
        >
          <FiMonitor />
        </button>
      </Show>
    </div>
  )
}
